<template>
<div>
	<v-dialog v-model="dialog" persistent max-width="900px">
		<v-card>
			<!-- Headre -->
			<v-card-title class="d-flex justify-space-between border-bottom">
				<!-- Выбор типа заявки -->
				<div class="d-flex">
					<!-- От жителя -->
					<v-btn
						v-if="!base_technical_supervisor && !requestId || (requestId && !innerRequest)"
						class="text-lowercase subtitle-2 mr-2"
						:class="innerRequest ? 'font-weight-light' : 'font-weight-bold'"
						:color="innerRequest ? 'secondary' : 'primary'"
						:text="innerRequest"
						depressed
						@click="innerRequest = false"
					>
						<span class="text-capitalize mr-1">Заявка</span>от жителя
					</v-btn>
					<!-- Внутренняя -->
					<v-btn
						v-if="
							(role !== 'dispatcher' && !requestId) ||
								(requestId && innerRequest)
						"
						class="text-lowercase subtitle-2"
						:class="innerRequest ? 'font-weight-bold' : 'font-weight-light'"
						:color="innerRequest ? 'primary' : 'secondary'"
						:text="!innerRequest"
						depressed
						@click="innerRequest = true"
					>
						<span class="text-capitalize mr-1">Внутренняя</span> заявка
					</v-btn>
				</div>
				<v-btn color="dark" @click="closeModal" icon>
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-card-title>
			<!-- Progress bar -->
			<v-progress-linear
				v-if="loading"
				color="primary"
				height="5"
				indeterminate
			></v-progress-linear>

			<!-- Body -->
			<v-card-text class="pb-0" v-else>
				<v-container>
					<v-form ref="form">
						<v-row>
							<!-- Адрес -->
							<v-col cols="12" sm="6" md="4">
								<Label>{{addressLabel}}</Label>
								<v-autocomplete
									v-model="data.house_id"
									class="border-all mt-2"
									solo
									dense
									hide-details
									flat
									item-text="apartment_address"
									item-value="id"
									required
									:filter="addressFilter"
									:items="houses"
									:disabled="userCreated && role !== 'dispatcher' || disableAddress"
									:rules="rules.required"
									@change="changeCity"
								></v-autocomplete>
							</v-col>
							<!-- Помещение внутренней заявки -->
							<v-col cols="12" sm="6" md="4" v-if="innerRequest && !base_technical_supervisor">
								<Label>Помещение</Label>
								<v-select
									v-model="data.premise_id"
									class="border-all mt-2"
									solo
									dense
									hide-details
									flat
									required
									item-text="name"
									item-value="id"
									:disabled="disablePremise"
									:items="nonResidentalPremises"
									:rules="rules.required"
								></v-select>
							</v-col>
							<v-col cols="12" sm="6" md="4" v-if="innerRequest && base_technical_supervisor">
								<Label>Местоположение</Label>
									<TextField
										v-model="data.location"
										class="mt-2"
										required
										:rules="rules.required"
									></TextField>
								</v-col>
							<!-- Помещение внутренней заявки -->
							<v-col cols="12" sm="6" md="4" v-if="base_technical_supervisor && innerRequest">
								<Label>Тип предписания</Label>
								<v-select
									v-model="data.visibility_id"
									class="border-all mt-2"
									solo
									dense
									hide-details
									flat
									required
									item-text="name"
									item-value="id"
									:disabled="userCreated"
									:items="request_visibility"
									:rules="rules.required"
								></v-select>
							</v-col>
							<!-- Тип помещения заявки от жителя -->
							<v-col cols="12" sm="6" md="4" v-if="!innerRequest">
								<Label>Тип помещения</Label>
								<v-select
									v-model="data.type_id"
									class="border-all mt-2"
									solo
									dense
									hide-details
									flat
									item-text="name"
									item-value="id"
									:items="catalog.room_types"
									required
									:disabled="userCreated && role !== 'dispatcher' || disablePremise"
									:rules="rules.required"
								></v-select>
							</v-col>
							<!-- Номер помещения -->
							<v-col cols="12" sm="6" md="4" v-if="!innerRequest">
								<Label v-if="data.type_id === '616958bd0b7a9927c42959b2'">Номер квартиры</Label>
								<Label v-else-if="data.type_id === '616958bd0b7a9927c42959b3'">Номер НП</Label>
								<Label v-else-if="data.type_id === '616958bd0b7a9927c42959b4'">Номер ПМ</Label>
								<Label v-else>Номер помещения</Label>
								<TextField
									v-model="data.apartment_number"
									class="mt-2"
									required
									:disabled="userCreated  && role !== 'dispatcher' || disableAppNumber"
									:rules="rules.aptNumber"
								></TextField>
							</v-col>
							<!-- Телефон заявителя -->
							<v-col cols="12" sm="6" md="4" v-if="!innerRequest">
								<Label>Телефон заявителя</Label>
								<TextField
									v-model="data.phone"
									:placeholder="phoneCode+'-'"
									v-mask="phoneCode == '+7' ? phoneCode+'-###-###-##-##' : phoneCode+'-###-###-####'"
									class="mt-2"
									required
									:disabled="userCreated && role !== 'dispatcher' || disablePhone"
									:rules="rules.phone"
								></TextField>
							</v-col>
							<!-- Дополнительный телефон -->
							<v-col cols="12" sm="6" md="4" v-if="!innerRequest">
								<Label>Дополнительный телефон</Label>
								<TextField
									v-model="data.additional_phone"
									:placeholder="phoneCode+'-'"
									v-mask="phoneCode == '+7' ? phoneCode+'-###-###-##-##' : phoneCode+'-###-###-####'"
									class="mt-2"
									:disabled="userCreated && role !== 'dispatcher' || disablePhone"
								></TextField>
							</v-col>
							<!-- Город -->
							<!-- <v-col cols="12" sm="6" md="4" v-if="!innerRequest">
								<Label>Город</Label>
								<v-autocomplete
									v-model="data.city_id"
									class="border-all mt-2"
									solo
									dense
									hide-details
									flat
									disabled
									:items="catalog.cities"
									item-value="id"
									item-text="name"
								></v-autocomplete>
							</v-col> -->
							<!-- Подьезд -->
							<v-col cols="12" sm="6" md="4" v-if="!innerRequest && data.type_id === '616958bd0b7a9927c42959b2'">
								<Label>Подъезд</Label>
								<!-- <v-autocomplete
									v-if="porchList.length && !requestId"
									v-model="data.porch_number"
									class="border-all mt-2 porch"
									solo
									dense
									hide-details
									flat
									:items="porchList"
									item-text="name"
									item-value="number"
									label="Выберите или введите"
									:disabled="userCreated && role !== 'dispatcher' || disablePorch"
									:rules="data.type_id === '616958bd0b7a9927c42959b2' ? rules.required : []"
									:menu-props="{ closeOnContentClick: false, closeOnClick: false }"
									clearable
									append-icon="mdi-plus-circle"
									@keyup="checkNewPorch"
							></v-autocomplete> -->
								<v-select
									v-if="porchList.length && !requestId"
									v-model="data.porch_number"
									class="border-all mt-2"
									solo
									dense
									hide-details
									flat
									item-text="name"
									item-value="name"
									:items="porchList"
									required
									:disabled="userCreated && role !== 'dispatcher' || disablePorch"
									:rules="data.type_id === '616958bd0b7a9927c42959b2' ? rules.required : []"
								></v-select>
								<TextField
									v-else
									v-model="data.porch_number"
									class="mt-2"
									required
									:disabled="userCreated && role !== 'dispatcher' || !data.house_id || data.type_id !== '616958bd0b7a9927c42959b2' || disablePorch"
									:rules="data.type_id === '616958bd0b7a9927c42959b2' ? rules.required : []"
								></TextField>
							</v-col>
							<!-- Этаж -->
							<v-col cols="12" sm="6" md="4" v-if="!innerRequest && data.type_id === '616958bd0b7a9927c42959b2'">
								<Label>Этаж</Label>
								<TextField
									v-model="data.floor_number"
									class="mt-2"
									required
									:disabled="userCreated && role !== 'dispatcher' || data.type_id !== '616958bd0b7a9927c42959b2' || disableFloor" 
									:rules="data.type_id === '616958bd0b7a9927c42959b2' ? rules.required : []"
								></TextField>
							</v-col>
							<v-col cols="12" sm="6" md="4" v-if="!innerRequest && data.type_id === '616958bd0b7a9927c42959b3'">
								<Label>Вид деятельности</Label>
								<TextField
									v-model="data.activity"
									class="mt-2"
									required
									:disabled="userCreated && role !== 'dispatcher' || data.type_id !== '616958bd0b7a9927c42959b3' || requestId !== ''" 
									:rules="data.type_id === '616958bd0b7a9927c42959b3' ? rules.required : []"
								></TextField>
							</v-col>
							<v-col cols="12" sm="6" md="4" v-if="!innerRequest && data.type_id === '616958bd0b7a9927c42959b3'">
								<Label>Наименование НП</Label>
								<TextField
									v-model="data._name"
									class="mt-2"
									required
									:disabled="userCreated && role !== 'dispatcher' || data.type_id !== '616958bd0b7a9927c42959b3' || requestId !== ''" 
									:rules="data.type_id === '616958bd0b7a9927c42959b3' ? rules.required : []"
								></TextField>
							</v-col>
							<v-col v-if="base_role === 'dispatcher'" cols="12" sm="6" md="4">
								<Label>УК</Label>
								<v-autocomplete
									v-model="data.company_id"
									class="border-all mt-2"
									:placeholder="data.house_id ? 'Отсутствует УК' : ''"
									solo
									append-icon=""
									dense
									hide-details
									flat
									disabled
									:items="kskList"
									item-value="id"
									item-text="name"
								></v-autocomplete>
							</v-col>
							<!-- Список категорий работ -->
							<v-col cols="12" v-if="!loading">
								<Label>{{categoryLabel}}</Label>
								<ChipsGroup
									v-if="showCategories"
									:chips="categoryList"
									:activeChipID.sync="data.request_category_id"
									:activeChip.sync="categoryIndex"
									:disabled="disableCategory"
									:showArrow="categoryList.length > 4"
								/>
							</v-col>
							<!-- Описание -->
							<v-col cols="12 pt-0">
								<v-textarea
									v-model="data.description"
									class="border-all"
									height="100px"
									no-resize
									solo
									hide-details
									flat
									dense
									:disabled="userCreated && role !== 'dispatcher' || disableDescription"
									:rules="rules.required"
								></v-textarea>
							</v-col>
							<!-- Список исполнителей -->
							<v-col cols="12" v-if="(base_role !== 'ksk_dispatcher' && base_role !== 'dispatcher' && !	hideReqExec) || (status === 'in_work' || status === 'accepted')">
								<RequisitionsExecutors
									v-if="status !== 'new' || status == 'new' && !requestId"
									:disable-executors="disableExecutors"
									:disable-date="disableDate"
									:disable-paid="disablePaid"
									:status="status"
									:paid.sync="data.is_price"
									:date.sync="data.period_execution"
									:chips.sync="data.employee_workers"
									:category-id="data.request_category_id"
									:houseId="data.house_id"
									:kskId="data.company_id"
									:nudgeTop="410"
									:inner="innerRequest"
									:calledFromModal="true"
									:price="data.price"
									:technicalSupervisor="base_technical_supervisor"
									@remove-chip="removeChip"
								/>
							</v-col>
							<!-- Alert -->
							<v-col cols="12" class="py-0">
								<v-alert
									v-if="alert"
									class="mb-0"
									:type="alert.color"
									:color="alert.color"
									outlined
									dense
								>
									{{ alert.message }}
								</v-alert>
							</v-col>
							<v-col v-if="isLoaded" cols="12">
								<FilesList :items="data.loaded_images" clearable />
							</v-col>
						</v-row>
					</v-form>
				</v-container>
				<span class="subtitle-2 grey--text font-weight-light pl-5">
					{{getLabelValue()}}
				</span>
			</v-card-text>
			<v-card-actions class="pt-0 pb-5 d-flex justify-space-between">
				<!-- Прикрепление файлов -->
				<v-file-input
					v-model="selectedFiles"
					@change="handleFileChange"
					:label-value="getLabelValue"
					label="Прикрепить"
					class="border-none mr-8 d-flex"
					accept=".jpg, .jpeg, .png, .xlsx, .xls, .pdf, .doc, .docx, .pptx, .ppsx, .odp"
					hide-details
					flat
					chips
					multiple
					solo
					dense
					show-size
					:disabled="disableFiles"
				></v-file-input>
				<v-btn
					class="subtitle-2 text-capitalize"
					color="primary"
					depressed
					:loading="isAddingRequest"
					@click="addRequest"
				>
					{{ requestId ? "Сохранить" : "Создать" }}
				</v-btn>
			</v-card-actions>
			<v-row style="padding: 8px 22px;">
				<v-col v-for="(image, i) in images" :key="i" class="position-relative">
					<img :src="image.url" class="notify-image">
					<v-btn
						@click="deleteLocalFile('image', i, image.id)"
						icon
						color="red"
						class="position-absolute"
						style="top: 5px; right: 5px;"
					>
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-col>
			</v-row>
			<!-- <v-row>
				<v-col v-for="(file, i) in filesType" :key="i">
					<span v-if="file.type !== 'image'">
						<a @click="downloadFile(file)">{{ file.name }}</a>
						<v-btn @click="deleteLocalFile(file.type, i, file.id)" icon color="red">
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</span>
				</v-col>
			</v-row> -->
		</v-card>
	</v-dialog>
	<ModalWarning
		v-if="warningDialog"
		:title="'К сожалению уведомления отключены'"
		:text="'Для получения сведений о статусе заявки необходимо включить уведомления на сайте и перезагрузить страницу'"
		@close-modal="closeWarningModal"
	/>
</div>
</template>

<script>
import { mapGetters } from "vuex";

import ChipsGroup from "@/components/app/ChipsGroup";
import FilesList from "@/components/app/FilesList";
import RequisitionsExecutors from "@/components/RequisitionsExecutors";
import Label from "./app/Label.vue";
import ModalWarning from "@/components/app/ModalWarning";

export default {
	name: "RequisitionsTableModal",
	components: { ChipsGroup, FilesList, RequisitionsExecutors, Label, ModalWarning },
	props: {
		requestId: {
			type: String,
			default: "",
		},
		status: {
			type: String,
			default: "new",
		},
		userCreated: {
			type: Boolean,
			default: false,
		},
		houseId: {
			type: String,
			default: "",
		},
		categoryId: {
			type: String,
			default: "",
		},
		images: {
			type: Array,
			default: () => [],
		},
		isDispatcher: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			alert: null,
			showCategories: true,
			hideReqExec: false,
			porchList: [],
			selectedFiles: [],
			user_files: [],
			data: {
				additional_phone: "",
				house_id: "",
				company_id: null,
				request_category_id: "",
				period_execution: "",
				description: "",
				employee_workers: [],
				is_price: false,
				phone: "",
				type_id: null,
				premise_id: null,
				visibility_id: null,
				location: '',
				floor_number: '',
				porch_number: '',
				firebase_token: 'test',
				source: 2,
				activity: '',
				_name: '',
			},
			files: [],
			filesType: [],
			deletedFiles: [],
			employee_workers: [],
			categoryList: [],
			categoryIndex: null,
			requestType: null,
			categoryIndexFirst: null,
			dialog: true,
			warningDialog: false,
			innerRequest: false,
			isAddingRequest: false,
			loading: true,
			isLoaded: false,
			disableDate: false,
			disableAddress: false,
			disablePremise: false,
			disableDescription: false,
			disableCategory: false,
			disableExecutors: false,
			disablePaid: false,
			disableFiles: false,
			disableAppNumber: false,
			disablePhone: false,
			disableFloor: false,
			disablePorch: false,
			rules: {
				required: [v => !!v || ""],
				phone: [
					v => !!v || "",
					v => this.phoneCode === '+7' ? ((v && v.length === 16) || this.$t("login.errors.incorrectPhoneFormat")) : (v && v.length > 14) || this.$t("login.errors.incorrectPhoneFormat"),
				],
				aptNumber: [
					v => !!v || "",
					v => (v && v.length < 50) || "Превышено кол-во символов",
				],
			},
		};
	},
	computed: {
		...mapGetters({
			catalog: "catalog/GET_CATALOG",
			housesKsk: "ksk/GET_HOUSES",
			housesDispatcher: "ksk/GET_HOUSES",
			employee: "ksk/GET_EMPLOYEE",
			base_role: "auth/GET_USER_ROLE",
			role: "auth/GET_USER_ROLE_VUEX",
			userId: "auth/GET_USER_ID",
			kskId: "auth/GET_KSK_ID",
			companyType: "auth/GET_COMPANY_TYPE",
			kskList: "catalog/GET_KSK_LIST",
			kskInfo: "ksk/GET_KSK_INFO",
			phoneCode: "auth/GET_USER_PHONE_CODE",
			base_technical_supervisor: "auth/GET_TECHNICAL_SUPERVISOR",
			technicalSupervisor: "auth/GET_TECHNICAL_SUPERVISOR_ROLE",
			tokenDevice: "notifications/GET_DEVICE_TOKEN",
		}),
		nonResidentalPremises() {
			return [...this.catalog.premises].splice(3);
		},
		residentalPremises() {
			return [...this.catalog.premises].splice(0, 3);
		},
		houses() {
			return this.base_role.includes("ksk") ||
				this.base_role === "ksk_dispatcher"
				? this.housesKsk
				: this.housesDispatcher;
		},
		request_visibility() {
			const requests = ["simple_prescription", "critical_prescription", "stop_order"];
			return this.catalog.request_visibility.filter(e => requests.includes(e.sys_name))
		},
		addressLabel() {
			return this.base_technical_supervisor ? 'Наименование объекта' : 'Адрес'
		},
		categoryLabel() {
			return this.companyType === 'sc' && this.requestType !== 'new' && !this.categoryList.length ? 'К сожалению нет категорий работ под данный адрес' : 'Категория работ'
		}
	},
	watch: {
		alert(val) {
			if (!val) return;
			setTimeout(() => {
				this.alert = null;
			}, 3000);
		},
		'categoryList'() {
			this.showCategories = false
			setTimeout(() => {
				this.showCategories = true
			}, 50);
		},
		'data.house_id'() {
			if(this.data.house_id && this.data.request_category_id) {
				if(this.base_role !== 'dispatcher') {
					if(this.companyType !== 'sc') {
						this.searchCompany()
					}
					this.$store.dispatch("ksk/load_employee", {house_id: this.data.house_id, category_id: this.data.request_category_id, requestInfo: true});
				} else {
					this.$store.dispatch("ksk/load_employee_dispatcher", {house_id: [this.data.house_id], category_id: this.data.request_category_id, selected_ksk_id: this.data.company_id, ksk_id: [this.data.company_id]});
				}
			}
			if(this.data.house_id && !this.innerRequest) {
				let params = {
					house_id: this.data.house_id
				}
				this.$api.dropdowns.load_porch_list(params)
				.then(res => {
					this.porchList = res;
				})
			}
			if(this.data.house_id && this.companyType === 'sc') {
				this.categoryList = this.kskInfo.company_house_category
					.filter(item => item.house.id === this.data.house_id)
					.map(item => item.category);
				if(!this.requestId) {
					this.disableCategory = false
				}
			}
		},
		'data.request_category_id'() {
			if(this.data.house_id && this.data.request_category_id) {
				if(this.base_role !== 'dispatcher') {
					if(this.companyType !== 'sc') {
						this.searchCompany()
					}
					this.$store.dispatch("ksk/load_employee", {house_id: this.data.house_id, category_id: this.data.request_category_id, requestInfo: true});
				} else {
					this.$store.dispatch("ksk/load_employee_dispatcher", {house_id: [this.data.house_id], category_id: this.data.request_category_id, selected_ksk_id: this.data.company_id, ksk_id: [this.data.company_id]});
				}
			}
		},
	},
	methods: {
		async searchCompany() {
			const params = {
				house_id: this.data.house_id,
				category_id: this.data.request_category_id
			}
			const response = await this.$api.ksk.searchCompany(params);
			if(response?.status === 200) {
				console.log('res', response?.status)
				this.hideReqExec = true
			} else {
				console.log('err', response)
				this.hideReqExec = false
			}
		},
		checkNewPorch(event) {
			const newValue = event.target.value;
			console.log('newValue', newValue)
			console.log('newValue 1', newValue && !this.porchList.find(porch => porch.number == newValue))
			console.log('newValue 2', this.porchList.find(porch => porch.number == newValue))
			if (newValue && !this.porchList.find(porch => porch.number == newValue)) {
				this.porchList.push({ name: newValue, number: newValue });
				this.data.porch_number = newValue
			}
    },
		async closeWarningModal() {
			this.warningDialog = false
			this.dialog = true
			if(this.base_technical_supervisor) this.innerRequest = true
			if (this.houseId) {
				this.data.house_id = this.houseId;
			}
			if (this.categoryId) {
				this.data.request_category_id = this.categoryId;
				this.categoryIndex = this.catalog.request_categories.findIndex(
					e => e.id === this.categoryId
				);
			}
			try {
				this.loading = true;
				if (this.requestId) {
					this.dialog = false;
					await this.loadRequestForEdit();
					this.dialog = this.isLoaded = true;
				}
				if (this.images) {
					this.data.loaded_images = [...this.images];
				}
				this.$store.dispatch("ksk/load_employee");
				this.loading = false;
			} catch (error) {
				console.error(error);
			}
		},
		deleteLocalFile(type, index, id) {
			this.deletedFiles.push(id)
			const fileIndex = this.files.findIndex(file => file === id);
			if (fileIndex !== -1) {
				this.files.splice(fileIndex, 1);
			}
			if(type === 'image') {
				this.images.splice(index, 1);
			} else {
				this.docs.splice(index, 1);
			}
		},
		handleFileChange(event) {
			if(!event.length) {
					this.user_files = []
					return this.selectedFiles = []
				}
				const newFiles = Array.from(event);
						const filteredFiles = newFiles.filter((file) => {
					return !this.user_files.some((image) => image.name === file.name && image.size === file.size);
				});
				this.user_files.push(...filteredFiles);
				this.selectedFiles = this.user_files.map((file) => new File([file], file.name));
			},
		getLabelValue() {
			if (this.user_files.length === 0) return '';
			if (this.user_files.length === 1) return this.selectedFiles[0].name;
			return `выбрано файлов - ${this.user_files.length}`;
		},
		changeCity(e) {
			console.log('e', e)
			const found = this.houses.find(h => h.id === e);
			console.log('e', e)
			console.log('found', found)
			this.data.company_id = found.company_id;
		},
		// Валидация
		customValidation() {
			let message;

			if (!this.$refs.form.validate()) {
				this.alert = {
					color: "error",
					message: "Заполните все выделенные поля",
				};
				return false;
			} else if (this.categoryIndex === null) {
				message = "Укажите категорию заявки";
			} else if (
				(this.base_role === 'ksk' && !this.data.period_execution && !this.hideReqExec && (this.status !== 'new' || this.status == 'new' && !this.requestId)) ||
				(this.base_role === 'ksk_manager' && !this.data.period_execution && !this.hideReqExec && (this.status !== 'new' || this.status == 'new' && !this.requestId)) ||
				(this.role !== "dispatcher" && !this.data.period_execution && !this.hideReqExec && (this.status !== 'new' || this.innerRequest))
			) {
				message = "Укажите срок исполнения";
			} else if (
				(this.base_role === 'ksk' && !this.data.employee_workers.length && !this.hideReqExec && (this.status !== 'new' || this.status == 'new' && !this.requestId))  ||
				(this.base_role === 'ksk_manager' && !this.data.employee_workers.length && !this.hideReqExec && (this.status !== 'new' || this.status == 'new' && !this.requestId)) ||
				(!this.data.employee_workers.length && !this.hideReqExec && (this.status !== 'new' || this.innerRequest)) 
			) {
				message = "Выберите как минимум одного исполнителя";
			}

			if (message) {
				this.alert = {
					color: "error",
					message,
				};
				return false;
			}
			return true;
		},

		async loadRequestForEdit() {
			// ПОМЕНЯТЬ ВСЕ ID НА SYS_NAME
			let res;
			// if(this.role == 'administrator') {
			res = await this.$api.ksk.load_request_detail(
				this.requestId
			);
			// } else {
			// 	res = await this.$api[this.role].load_request_detail(
			// 		this.requestId
			// 	);
			// }
			this.requestType = res.request_type.sys_name
			this.disableFloor = true,
			this.disablePorch = true,
			this.innerRequest = res.is_inner;
			this.data.company_id = res.company.id;
			if(res.room && res.room.type && res.room.type.id === '616958bd0b7a9927c42959b3') {
				this.data.activity = res.room.activity
				this.data._name = res.room.name
			}
			for (const key in this.data) {
				if (res[key]) {
					this.data[key] = res[key];
				}
			}
			if(this.base_role !== 'dispatcher') {
				// В работе
				if(res.is_inner && res.request_type.id === '5f4cda330796c90b114f5561') {
					this.disableAddress = true
					this.disablePremise = true
					this.disableDescription = true
					this.disablePaid = true
					this.disableFiles = true
				}
				// Новая
				if(!res.is_inner && res.request_type.id === '5f4cda320796c90b114f555f' && res.created_by === 'user') {
					this.disableAddress = true
					this.disablePremise = true
					this.disableDescription = true
					this.disableExecutors = true
					this.disableDate = true
					this.disablePaid = true
					this.disableFiles = true
				}
				if(!res.is_inner && res.request_type.id === '5f4cda320796c90b114f5560' && res.created_by === 'user') {
					this.disableAddress = true
					this.disablePremise = true
					this.disableDescription = true
				}
				if(!res.is_inner && res.request_type.id === '5f4cda330796c90b114f5561' && res.created_by === 'user') {
					this.disableAddress = true
					this.disablePremise = true
					this.disableDescription = true
				}
				if(!res.is_inner && res.request_type.id === '5f4cda320796c90b114f5560' && res.created_by !== 'user') {
					this.disableAddress = true
					this.disablePremise = true
					this.disableAppNumber = true
					this.disablePhone = true
					if(res.created_by === 'company') {
						this.disablePaid = true
					}
				}
				if(!res.is_inner && res.request_type.id === '5f4cda330796c90b114f5561' && res.created_by !== 'user') {
					this.disableAddress = true
					this.disablePremise = true
					this.disableDescription = true
					this.disableAppNumber = true
					this.disablePhone = true
				}
				// Новая заявка 
				if(res.request_type.id === '5f4cda320796c90b114f555f' && res.created_by === 'dispatcher') {
					this.disableAddress = true
					this.disablePremise = true
					this.disableAppNumber = true
					this.disablePhone = true
					this.disablePorch = true
					this.disableFloor = true
					this.disableExecutors = true
					this.disablePaid = true
				}
			} else {
				// Новая
				if(!res.is_inner && res.request_type.id === '5f4cda320796c90b114f555f' && res.created_by === 'user') {
					this.disableAddress = true
					this.disablePremise = true
					this.disableExecutors = true
					this.disableDate = true
					this.disablePaid = true
					this.disableAppNumber = true
					this.disablePhone = true
					this.disablePorch = true
					this.disableFloor = true
				}
				// Новая dispatcher
				if(!res.is_inner && res.request_type.id === '5f4cda320796c90b114f555f' && res.created_by === 'dispatcher') {
					this.disableAddress = true
					this.disablePremise = true
					this.disableExecutors = true
					this.disableDate = true
					this.disablePaid = true
					this.disableAppNumber = true
					this.disablePhone = true
					this.disableFloor = true
					this.disablePorch = true
				}
				// Назначено user NOT INNER
				if(!res.is_inner && res.request_type.id === '5f4cda320796c90b114f5560' && res.created_by === 'user') {
					this.disableAddress = true
					this.disablePremise = true
					this.disableAppNumber = true
					this.disablePhone = true
					this.disablePorch = true
					this.disableFloor = true
					this.disableDescription = true
				}
				// Назначено NOT user, NOT INNER
				if(!res.is_inner && res.request_type.id === '5f4cda320796c90b114f5560' && res.created_by !== 'user') {
					this.disableAddress = true
					this.disablePremise = true
					this.disableAppNumber = true
					this.disablePhone = true
					this.disablePorch = true
					this.disableFloor = true
					this.disablePaid = true
				}
				// Назначено NOT user, TRUE INNER
				if(res.is_inner && res.request_type.id === '5f4cda320796c90b114f5560' && res.created_by !== 'user') {
					this.disableAddress = true
					this.disableAppNumber = true
					this.disablePhone = true
					this.disablePorch = true
					this.disableFloor = true
					this.disablePaid = true
				}
				// В работе user, NOT INNER
				if(!res.is_inner && res.request_type.id === '5f4cda330796c90b114f5561' && res.created_by === 'user') {
					this.disableAddress = true
					this.disablePremise = true
					this.disableAppNumber = true
					this.disablePhone = true
					this.disablePorch = true
					this.disableFloor = true
					this.disableDescription = true
				}
				// В работе NOT user, NOT INNER
				if(!res.is_inner && res.request_type.id === '5f4cda330796c90b114f5561' && res.created_by !== 'user' && res.created_by !== 'dispatcher') {
					this.disableAddress = true
					this.disablePremise = true
					this.disableAppNumber = true
					this.disablePhone = true
					this.disablePorch = true
					this.disableFloor = true
					this.disablePaid = true
				}
				// В работе, dispatcher, NOT INNER
				if(!res.is_inner && res.request_type.id === '5f4cda330796c90b114f5561' && res.created_by === 'dispatcher') {
					this.disableAddress = true
					this.disablePremise = true
					this.disableAppNumber = true
					this.disablePhone = true
					this.disablePorch = true
					this.disableFloor = true
					this.disablePaid = true
				}
				// В работе NOT user, TRUE INNER
				if(res.is_inner && res.request_type.id === '5f4cda330796c90b114f5561' && res.created_by !== 'user') {
					this.disableAddress = true
					this.disablePremise = true
					this.disableAppNumber = true
					this.disablePhone = true
					this.disablePorch = true
					this.disableFloor = true
					this.disablePaid = true
					this.disableFiles = true
					this.disableDescription = true
				}
			}
			if(this.base_role === 'ksk' || this.base_role === 'ksk_manager') {
				if(res.request_type.id === '5f4cda320796c90b114f5560' || res.request_type.id === '5f4cda330796c90b114f5561') {
					this.disableDescription = false
				}
			}
			if(!res.is_sc && (res.request_type.sys_name === 'accepted' || res.request_type.sys_name === 'in_work') && res.house) {
				this.getHouseCategory(res.house.id, res.request_category.id)
			} else {
				this.data.request_category_id = res.request_category.id
				this.categoryIndex = this.catalog.request_categories.findIndex(
					e => e.id === res.request_category.id
				);
			}

			console.log('this.categoryIndex', this.categoryIndex)
			if(this.companyType === 'sc' && res.is_sc && res.request_type.sys_name !== 'new') {
				this.categoryList = this.kskInfo.company_house_category
					.filter(item => item.house.id === res.house.id)
					.map(item => item.category);
				this.categoryIndex = this.categoryList.findIndex(
					e => e.id === res.request_category.id
				);
				this.disableCategory = true
			}
			if(res.is_inner) {
				this.disableAddress = true
			}
			if (res.user?.phone) {
				this.data.phone = res.user.phone;
			}
			if (res?.apartment?.house_id || res?.house?.id) {
				this.data.house_id = res?.apartment?.house_id || res?.house?.id;
			}
			if (res?.room?.apartment_number) {
				this.data.apartment_number = res.room.apartment_number;
			}
			if (res?.room?.porch_number) {
				this.data.porch_number = res.room.porch_number;
			}
			if (res?.room?.floor_number) {
				this.data.floor_number = res.room.floor_number;
			}
			if (res?.room?.type) {
				this.data.type_id = res.room.type.id;
			}
			if (res?.employee_worker_executor) {
				this.data.employee_executor = res.employee_worker_executor;
			}
			if(res.premise?.id) {
				this.data.premise_id = res.premise.id
			}
			if (this.data?.period_execution === null) {
				this.data.period_execution = "";
			}
			if (res?.employee_workers?.length) {
				let arr;
				if (typeof res.employee_workers[0] === "string") {
					arr = res.employee_workers.map(e =>
						this.employee.find(o => o._id === e)
					);
				} else {
					arr = res.employee_workers.map(e =>
						this.employee.find(o => o._id === e._id)
					);
				}
				this.data.employee_workers = [...arr];
			}
			if(this.role == 'dispatcher') {
				this.data.employee_workers = res.employee_workers
			}
			this.user_files = [];
			this.data.edit = true;
			res.files.forEach(element => {
				if(element.type === 'image') {
					this.loadServerImage(element)
				} else {
					this.filesType.push(element)
				}
				this.files.push(element.id)
			});
		},
		async getHouseCategory(id, categoryId) {
			try {
        const res = await this.$api.ksk.load_houses_category(id)
				const excludedCategoryIds = res.map(item => item.category.id);
				console.log('excludedCategoryIds', excludedCategoryIds)
				this.categoryList = this.catalog.request_categories.filter(category => 
					!excludedCategoryIds.includes(category.id)
				);
				this.data.request_category_id = categoryId
				this.categoryIndex = this.categoryList.findIndex(
					e => e.id === categoryId
				);
      } catch (error) {
        console.log('err', error)
      }
		},
		async loadServerImage(avatar, showDefault = true) {
      if (!avatar && showDefault) {
        this.images.push("@/assets/user.svg");
        return; 
      }

      if (avatar?.id) {
        try {
          const response = await this.$api.ksk.load_file(avatar.id);
          const blob = new Blob([response], { type: 'application/octet-stream' });
          const blobUrl = URL.createObjectURL(blob);
          this.images.push({url: blobUrl, id: avatar.id});
        } catch (error) {
          console.error('Ошибка при загрузке изображения:', error);
          this.images.push("@/assets/user.svg");
        }
      } else {
        this.imageSrc = require("@/assets/user.svg");
      }
    },
		async uploadFiles(file) {
			try {
				let data = {
					file: file
				}
				const formData = data.toFormData();
				const res = await this.$api.ksk.load_files(formData);
				this.files.push(res.id)
				console.log('this.files push', this.files)
			} catch (error) {
				console.log('err', error)
				this.isAddingRequest = false;
				this.alert = {
					color: "error",
					message: error.message.response.data.message || "Неподдерживаемый файл",
				};
				throw error;
			}

		},
		async deleteFiles() {
			if(this.files.length) {
				console.log('this.files', this.files)
				for(let i = 0; i < this.files.length; i++) {
					await this.$api.ksk.delete_files(this.files[i]);
				}
				this.files = []
				console.log('this.files 2', this.files)
			}
		},

		// Добавление заявки
		async addRequest() {
			this.alert = null;
			if (!this.customValidation()) return;
			this.isAddingRequest = true;
			if(this.selectedFiles.length) {
				for(let i = 0; i < this.selectedFiles.length; i++) {
					await this.uploadFiles(this.selectedFiles[i])
				}
			}

			// Оставить только _id исполнителей
			const employee_workers = [...this.data.employee_workers];
			this.data.employee_workers = this.data.employee_workers.map(
				e => e.id
			);
			// Ответственное лицо по дефолту первый выбранный исполнитель
			if(!this.technicalSupervisor) this.data.employee_worker_executor_id = this.data.employee_workers[0];
			// this.data.is_manager = Number(this.innerRequest);

			if (this.innerRequest) {
				const reqVis = this.catalog.request_visibility.find(e => e.sys_name === 'publicly_available')
				this.data.request_visibility_id = reqVis.id
				this.data.is_inner = this.innerRequest;
				this.data.user_id = this.userId
				delete this.data.phone;
				delete this.data.additional_phone;
				delete this.data.porch_number;
				delete this.data.floor_number;
				// delete this.data.is_price;
				// this.data.is_public = false
			} else {
				const reqVis = this.catalog.request_visibility.find(e => e.sys_name === 'publicly_available')
				this.data.request_visibility_id = reqVis.id
				this.data.is_inner = false
				this.data.is_public = false
				delete this.data.premise_id;
			}
			if(!this.technicalSupervisor) {
				delete this.data.visibility_id;
				delete this.data.location;
			}
			// if(this.base_role !== 'dispatcher') this.data.manager_id = this.userId
			this.data.manager_id = this.userId
			console.log('files!', this.files)
			this.data.user_files = this.files
			if(this.data.type_id !== '616958bd0b7a9927c42959b2') {
				delete this.data.porch_number;
				delete this.data.floor_number;
			}
			if(this.data.type_id !== '616958bd0b7a9927c42959b3') {
				delete this.data.activity;
				delete this.data._name;
			}
			if(this.role == 'dispatcher') {
				this.data.created_by = 'dispatcher'
				this.data.is_public = false
				this.data.request_visibility_id = '5f4cda320796c90b114f555e'
			} else {
				this.data.created_by = 'company'
			}

			try {
				let res;
				this.data.firebase_token = this.tokenDevice
				// Добавление новой заявки
				if (!this.requestId) {
					res = await this.$api[this.role].add_request(this.data);
					this.$root.snackbar.show({ color: "success", message: res.message || "Успешно" });
					this.$emit("update-info");
				} else {
					if (this.deletedFiles.length) {
						this.deletedFiles.forEach(el => {
							this.deleteFile(el);
						});
					}
					// Изменение информации существующей заявки
					this.data._method = 'patch'
					res = await this.$api[this.role].save_request(
						this.data,
						this.requestId
					);
					this.$emit("update-info");
				}

				this.data.employee_workers = [...employee_workers];

				if (res.error) {
					throw res;
				}

				// this.alert = {
				// 	color: "success",
				// 	message: res.success || "Заявка успешно создана!",
				// };

				setTimeout(() => this.closeModal(), 500);
				// Сбсросить данные, если была созданая новая заявка
				if (!this.requestId) {
					this.user_files = []
					this.data = {
						additional_phone: "",
						house_id: "",
						request_category_id: "",
						period_execution: "",
						description: "",
						employee_workers: [],
						is_price: false,
						phone: "",
						type_id: null,
					};
				}
				this.$refs.form.resetValidation();
			} catch (error) {
				this.deleteFiles()
				this.alert = {
					color: "error",
					message: error.message || "Произошла ошибка. Повторите еще раз.",
				};
			} finally {
				this.isAddingRequest = false;
			}
		},
		closeModal() {
			this.dialog = false;
			this.$emit("close-modal");
		},
		removeChip(index) {
			this.data.employee_workers.splice(index, 1);
		},
		async downloadFile(file) {
			const response = await this.$api.ksk.load_file(file.id);
			const blob = new Blob([response], { type: 'application/octet-stream' });
			const blobUrl = URL.createObjectURL(blob);
			const link = document.createElement('a');
			link.href = blobUrl;
			link.download = file.name;
			link.click();
			URL.revokeObjectURL(blobUrl);
		},
		async deleteFile(id) {
			try {
				await this.$api.ksk.delete_files(id)
			} catch (error) {
				console.error('Ошибка при удалении изображения:', error);
				this.alert = {
					color: 'error',
					message: 'Ошибка при удалении изображения:' + error,
				};
			}
		},
	},
	beforeCreate() {
		// this.$store.dispatch("dispatcher/load_houses");
		this.$store.dispatch("catalog/loadKskList");
	},
	async created() {
		if(!this.tokenDevice) {
			this.dialog = false
			this.warningDialog = true		
		} 
		if(this.base_technical_supervisor) this.innerRequest = true
		if (this.houseId) {
			this.data.house_id = this.houseId;
		}
		if(this.categoryId) {
			this.data.request_category_id = this.categoryId;
			this.categoryIndex = this.catalog.request_categories.findIndex(
				e => e.id === this.categoryId
			);
		}
		this.categoryList = this.catalog.request_categories
		if(!this.requestId && this.companyType === 'sc') {
			this.disableCategory = true
		}
		try {
			this.loading = true;
			if (this.requestId) {
				this.dialog = false;
				await this.loadRequestForEdit();
				this.dialog = this.isLoaded = true;
			} else {
			}
			if (this.images) {
				this.data.loaded_images = [...this.images];
			}
			// this.$store.dispatch("ksk/load_employee");
			this.loading = false;
		} catch (error) {
			console.error(error);
		}
	},
};
</script>

<style scoped>
.notify-image {
	width: 140px;
	max-height: 130px;
	border-radius: 4px;
}
</style>

<style>
.porch .v-label {
	font-size: 14px !important;
}
</style>