<template>
	<div class="requisitions-item-right d-flex flex-column border-left">
		<div class="pt-7 px-1 border-bottom d-flex justify-center">
			<v-tabs v-model="tab" slider-size="3">
				<v-tab class="caption">Комментарии</v-tab>
				<v-tab class="caption">История заявки</v-tab>
			</v-tabs>
		</div>
		<v-tabs-items v-model="tab" class="flex-sm-grow-1">
			<v-tab-item>
				<RequisitionsItemRightComments
					:comments="comments"
					:loading="commentsLoaded"
					:isPause="isPause"
					@update-comments="loadComments($event)"
				/>
			</v-tab-item>
			<v-tab-item>
				<RequisitionsItemRightHistory
					:history="history"
					:loading="historyLoaded"
				/>
			</v-tab-item>
		</v-tabs-items>
	</div>
</template>

<script>
import RequisitionsItemRightComments from "@/components/RequisitionsItemRightComments";
import RequisitionsItemRightHistory from "@/components/RequisitionsItemRightHistory";
import { mapGetters } from "vuex";
export default {
	name: "RequisitionsItemRight",
	components: {
		RequisitionsItemRightComments,
		RequisitionsItemRightHistory,
	},
	props: ['historyInfo', 'isPause'],
	data() {
		return {
			tab: null,
			loadEnable: false,
			comments: [],
			commentsLoaded: false,
			history: [],
			historyLoaded: false,
			id: this.$route.params.id,
		};
	},

	computed: {
		...mapGetters({
			role: "auth/GET_USER_ROLE_VUEX",
			kskId: "auth/GET_KSK_ID",
		}),
		catalog() {
			return this.$store.getters["catalog/GET_CATALOG"];
		},
	},

	watch: {
		// ? Ручное отслеживание маршрута, чтобы обновлять данные о сотрудниках
		"$route"() {
			this.id = this.$route.params.id;
			this.loadComments();
			// this.loadHistory();
		},
		"historyInfo"() {
			this.history = this.historyInfo
			// this.historyLoaded = true;
		}
		// "$route.params.id"() {
		// 	console.log('tab', this.tab)
		// 	this.loadHistory();
		// }
	},
	methods: {
		getChildComments() {
			for (let i = 0; i < this.comments.length; i++) {
				const e = this.comments[i];
				if (e.childCount) {
					e.child.forEach(c => (c.parent_comment = e.comment));
					this.comments.splice(i, 0, ...e.child);
					i += e.childCount;
				}
			}
		},

		async loadComments(params) {
			try {
				this.commentsLoaded = false;
				let res = await this.$api.ksk.load_request_comments({
					record_id: this.id,
					page: 1,
					selected_ksk_id: this.kskId,
					...params
				});
				this.commentsLoaded = true;
				this.comments = res.records;
				this.getChildComments();
			} catch (error) {
				throw error;
			}
		},

		async loadHistory(history) {
			this.history = history
			// console.log('this.loadEnable', this.loadEnable)
			// if(this.tab == 1 || this.loadEnable) {
			// 	try {
			// 		// * Получение истории
			// 		this.historyLoaded = false;
			// 		const history = await this.$api[this.role].load_request_detail(this.id);
			// 		const details = await this.$api.ksk.load_request_history(this.id);
			// 		const { request_types } = this.catalog;
			// 		console.log('history', history);
			// 		console.log('details', details);
			// 		details.forEach(d => {
			// 			const request = request_types.find(r => r._id === d.request_type_id);
			// 			if (!request) return;
			// 			const { sys_name } = request;
			// 			if (!sys_name) return;
			// 			history[`${sys_name}_user`] = d.user;
			// 			history[`${sys_name}_time`] = d.created_at;
			// 		});

			// 		if (history.user_created && !history["new_user"]) {
			// 			history["new_user"] = { ...history.user, isUser: true };
			// 		}

			// 		this.history = history;
			// 	} catch (error) {
			// 		throw error;
			// 	} finally {
			// 		this.historyLoaded = true;
			// 		this.loadEnable = false
			// 	}
			// }
			
		},
	},
	async created() {
		try {
			await this.loadComments();
		} catch (error) {
			console.log(error);
		}
	},
};
</script>

<style lang="scss" scoped>
::v-deep .v-slide-group__prev,
::v-deep .v-slide-group__next {
	display: none !important;
}
::v-deep .v-window__container,
::v-deep .v-window-item {
	height: 100% !important;
}
::v-deep .v-slide-group__content {
	justify-content: center !important;
}
</style>
