<template>
	<v-hover v-slot="{ hover }">
		<!-- min-width="280" -->

		<v-card
			width="100%"
			:elevation="hover ? 5 : 0"
			:class="{ 'on-hover': hover }"
			class="mb-3 transition-fast-in-fast-out"
			:active-class="'card-active'"
			:exact-active-class="'card-active'"
			:exact-path="'card-active'"
			outlined
			link
			:to="{
				path: `/requisitions/${item.id}`,
				query: $route.query,
			}"
			@click="$emit('change-page')"
		>
			<v-card-title
				class="border-bottom subtitle-2 d-flex justify-space-between align-center pa-3"
			>
				<span v-if="!base_technical_supervisor" class="subtitle-1 font-weight-light">
					{{ item.request_category.name }}
				</span>
				<span v-else class="subtitle-1 font-weight-light">
					{{ item.category_id | getCatalogValue("request_categories_technical_supervision") }}
				</span>
				<span>№{{ item.pretty_id }}</span>
			</v-card-title>
			
			<v-card-text class="border-bottom pa-3 d-flex flex-column">
				<span class="caption font-weight-medium">
					<!-- {{ getAddress() }} -->
					{{ item.house.address }}
				</span>
				<div class="d-flex justify-space-between flex-md-row flex-sm-column">
					<span class="caption font-weight-medium">
						{{ item.created_at | timestamp }}
					</span>
					<span
						class="caption font-weight-light"
						:class="`${deadline()}--text`"
					>
						Срок: {{ item.period_execution | timestamp(true, { hideTime: true }) }}
					</span>
				</div>
			</v-card-text>

			<v-card-actions class="pa-3 d-flex justify-space-between">
				<span class="caption font-weight-light">
					Статус:
					<span class="font-weight-bold" :class="`${color(item.request_type.sys_name)}--text`">
						{{ item.request_type.name }}
					</span>
				</span>
				<span v-if="item.unread_comment">
					<v-badge color="red" dot overlap bordered>
						<v-icon color="primary" small>mdi-message-outline</v-icon>
					</v-badge>
					<span class="ml-1 body-2">{{ item.unread_comment }}</span>
				</span>
			</v-card-actions>
		</v-card>
	</v-hover>
</template>

<script>
import { mapGetters } from "vuex";

import { statusColor } from "@/helpers/variables.js";

export default {
	name: "RequisitionsItemListCard",
	props: {
		item: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			statusColor,
		};
	},
	computed: {
		...mapGetters({
			catalog: "catalog/GET_CATALOG",
			base_technical_supervisor: "auth/GET_TECHNICAL_SUPERVISOR",
		}),
	},
	created() {},
	methods: {
		color(sys_name) {
			return this.statusColor[sys_name];
		},
		deadline() {
			const today = new Date().setHours(0, 0, 0, 0);
			const deadline_date = new Date(
				this.item.employee_period_of_execution
			).setHours(0, 0, 0, 0);
			if (today === deadline_date) {
				return "warning";
			}
			if (today > deadline_date) {
				return "error";
			}
			return "";
		},

		getAddress() {
			const address = this.item.apartment.house.apartment_address;
			const apartment = this.item.apartment.apartment_number || "---";
			const place = this.catalog.premises.filter(e => e.id === this.item.premise_id)
			if(this.item.premise_id) {
				if(place[0].name == 'Квартира') {
					return `${address}, кв.${apartment}`;
				} else {
					return `${address}, №${apartment}`;
				}
			} else {
				return `${address}, №${apartment}`;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.v-card {
	width: 100% !important;
	&.card-active {
		border: thin solid #2eb85c !important;
		background: rgba(46, 184, 92, 0.09);
	}
}
</style>
