<template>
	<div class="requsitions-item-info d-flex flex-column">
		<!-- Loader -->
		<v-progress-linear
			v-if="loading"
			color="primary"
			height="5"
			indeterminate
		></v-progress-linear>

		<!-- Header -->
		<div
			class="border-bottom pa-5 py-6 d-flex justify-space-between align-center"
			v-if="!loading"
		>
			<div class="d-flex justify-space-between align-center">
				<span class="text-subtitle-1 mr-2 font-weight-regular">
					{{ item.is_inner ? 'Внутренняя' : (item.is_public ? 'Публичная' : 'Индивидуальная') }}
					 №{{ item.pretty_id }}
				</span>
				<v-btn
					class="font-weight-bold text-capitalize text-caption"
					:color="item.is_overdue ? 'red' : statusColor[sys_name]"
					depressed
					outlined
					small
					:ripple="false"
					style="pointer-events: none"
				>
					{{ item.request_type.name }}
				</v-btn>
				<v-btn
					v-if="item.is_overdue"
					class="ml-2 font-weight-bold text-capitalize text-caption"
					color="red"
					depressed
					outlined
					small
					:ripple="false"
					style="pointer-events: none"
				>
					Просрочено
				</v-btn>
			</div>
			<!-- Не показывать кнопку редактирования
          у заявок со статусом "Исполнено", "Отклонено", "Отменено" -->
			<v-btn
				class="text-subtitle-2 text-capitalize ma-0"
				color="primary"
				text
				small
				v-if="editAvailable && !base_technical_supervisor"
				@click="showEditModal = true"
				:disabled="item.is_pause || (companyType !== 'sc' && item.is_sc)"
			>
				Редактировать
			</v-btn>
			<div v-if="sys_name === 'done'" class="rating">
				<v-btn
					v-if="
						role == 'dispatcher' && (item.user_rating === null || item.user_rating === 0) && item.user_comment === null || (role == 'ksk' || role == 'ksk_manager') && item.is_inner && (item.user_rating === null || item.user_rating === 0) && item.user_comment === null
					"
					text
					small
					depressed
					color="primary"
					@click="showRatingModal = true"
				>
					Поставить оценку
				</v-btn>
				<span class="text-vbtn" v-else-if="item.user_rating < 1 || item.user_rating === null">Без оценки</span>
				<v-rating
					v-else
					:value="item.user_rating || 0"
					color="amber"
					background-color="amber"
					dense
					half-increments
					readonly
					size="17"
				></v-rating>
			</div>
		</div>

		<!-- Body -->
		<div class="body d-flex flex-column flex-sm-grow-1" v-if="!loading">
			<!-- Информация -->
			<div class="body-info border-bottom pa-5 d-flex justify-space-between">
				<div class="d-flex flex-column">
					<span class="subtitle-1 font-weight-bold">Категория</span>
					<span v-if="!base_technical_supervisor" class="subtitle-1 font-weight-light">
						{{ item.request_category.name }}
					</span>
					<span v-else class="subtitle-1 font-weight-light">
						{{ item.category_id | getCatalogValue("request_categories_technical_supervision") }}
					</span>
				</div>
				<!-- Адрес -->
				<div class="d-flex flex-column">
					<span class="subtitle-1 font-weight-bold">Адрес</span>
					<span class="subtitle-1 font-weight-light">
						{{ getApartment(item) }}
						<!-- {{ item.house.address }} -->
					</span>
				</div>
				<!-- Телефон -->
				<div class="d-flex flex-column">
					<span class="subtitle-1 font-weight-bold">Телефон заявителя</span>
					<span class="subtitle-1 font-weight-light">
						{{ item.is_inner ? (item.user_created ? item.user_created.phone : "Не указан") : (item.user ? item.user.phone : "Не указан") }}
					</span>
					<span
						class="subtitle-2 font-weight-light"
						v-if="item.additional_phone"
					>
						{{ item.user ? item.additional_phone : "Не указан" }}
					</span>
				</div>
			</div>
			<!-- Описание и фото -->
			<div class="border-bottom pa-5 d-flex flex-column">
				<p class="mb-5">
					<span v-if="item.description" class="font-weight-bold">
						{{ item.description }}
					</span>
					<span v-else class="subtitle-2 font-weight-light grey--text">
						Описание отсутствует
					</span>
				</p>
				<ImagesList :images="item.files" />
			</div>
			<!-- Детали -->
			<div class="border-bottom pa-5 pr-8 d-flex flex-column">
				<RequisitionsExecutors
					:status="sys_name"
					:isRequestInfo="true"
					:paid.sync="item.is_price"
					:price="item.price"
					:date.sync="item.period_execution"
					:chips.sync="item.employee_workers"
					:nudgeTop="410"
					:houseId="item.house.id"
					:kskId="item.company.id"
					:categoryId="item.category_id"
					:disabled="sys_name !== 'new' || item.is_pause"
					:inner="item.inner"
					:managerItr="manager_itr"
					:technicalSupervisor="technicalSupervisor"
					@remove-chip="removeChip"
				/>
			</div>
			<!-- Footer статуса "Исполнено" -->
			<div
				class="border-bottom pa-5 d-flex flex-column"
				v-if="item.user_comment"
			>
				<span class="font-weight-light subtitle-2 mb-3">
					Комментарий жителя
				</span>
				<p class="mb-0 font-weight-light">
					{{ item.user_comment || "---" }}
				</p>
			</div>
			<div
				class="border-bottom pa-5 d-flex flex-column"
				v-if="item.employee_comment"
			>
				<span class="font-weight-light subtitle-2 mb-3">
					Комментарий по выполненной работе:
				</span>
				<span v-if="item.employee_worker_executor" class="subtitle-2 mb-3">
					{{item.employee_worker_executor | fullname}}
					{{item.employee_worker_executor.phone}}
				</span>
				<p class="mb-0 font-weight-light">
					{{ item.employee_comment || "---" }}
				</p>
			</div>
			<!-- Фотоотчет по выполненной работе -->
			<div v-if="sys_name === 'done'" class="d-flex flex-column pa-5 pb-0">
				<span class="font-weight-light subtitle-2 mb-3">
					Фотоотчет по выполненной работе
				</span>
				<ImagesList
					v-if="item.employee_files"
					:images="item.employee_files"
				/>
				<span v-else class="font-weight-light grey--text subtitle-2">
					Фотографии не прикреплены
				</span>
			</div>
			<!-- Alert -->
			<div class="pa-5">
				<v-alert v-if="alert" dense outlined :type="alert.color">
					{{ alert.message }}
				</v-alert>
			</div>
			<div v-if="item.is_pause" class="pa-5 text-center">
				<v-alert dense outlined type="error">
					Заявка находится на паузе, нажмите "Продолжить работу" для восстановления
				</v-alert>
			</div>
			<!-- Footer (для всех типов заявок, кроме Исполненных) -->
			<div
				v-if="sys_name !== 'done' && sys_name !== 'not_done'"
				class="pa-5 d-flex flex-sm-grow-1"
				:class="
					sys_name === 'done' ? 'justify-start pt-2 flex-column' : 'justify-end'
				"
			>
				<div v-if="item.is_pause">
					<DatePicker
						:allowPastDates="false"
						:nudgeTop="410"
						:date.sync="dateCopy"
						:btnClass="'subtitle-1 font-weight-medium text-capitalize'"
					/>
					<v-btn
						class="font-weight-regular text-capitalize subtitle-2 mr-2"
						color="primary"
						depressed
						:loading="isContinueStatus"
						@click="continueRequest"
					>
						Продолжить работу
					</v-btn>
				</div>
				<div v-else-if="(item.is_sc && companyType === 'sc') || (!item.is_sc && companyType !== 'sc') || this.base_role === 'dispatcher'" class="d-flex flex-sm-grow-1" :class="
					sys_name === 'done' ? 'justify-start pt-2 flex-column' : 'justify-end'
				">
					<v-btn
						v-if="sys_name === 'new' && !manager_itr && !technicalSupervisor"
						class="font-weight-regular text-capitalize subtitle-2 mr-2"
						color="error darken-1"
						depressed
						text
						:loading="isChangingStatus"
						@click="showRejectModal = true"
					>
						Отклонить
					</v-btn>
					<!-- Статус "в работе" -->
					<v-btn
						v-if="!manager_itr && (sys_name === 'in_work' || sys_name === 'overdue')"
						class="font-weight-regular subtitle-2 text-lowercase"
						color="primary"
						depressed
						:loading="isChangingStatus"
						@click="showFinishModal = true"
					>
						<span class="text-capitalize mr-1">Закрыть</span>заявку
					</v-btn>
					<!-- Статус "Принято" -->
					<v-btn
						v-else-if="sys_name === 'accepted' && !manager_itr && !technicalSupervisor"
						class="font-weight-regular text-lowercase subtitle-2"
						color="primary"
						depressed
						:loading="isChangingStatus"
						@click="acceptRequestForWork"
					>
						<span class="text-capitalize mr-1">Принять</span>в работу
					</v-btn>
					<!-- Статус "Новая" -->
					<div v-else-if="sys_name === 'new'" class="d-flex">
						<v-btn
							v-if="!technicalSupervisor"
							class="font-weight-regular text-capitalize subtitle-2"
							color="primary"
							depressed
							:loading="isChangingStatus"
							@click="acceptRequest"
						>
							Принять
						</v-btn>
					</div>
				</div>
			</div>
			<!-- Footed end -->
		</div>
		<RequisitionsItemModalFinish
			v-if="showFinishModal"
			:item="item"
			@close-modal="finishRequest"
		/>
		<RequisitionsItemModalReject
			v-if="showRejectModal"
			@close-modal="rejectRequest"
		/>
		<RequisitionsItemModalWarning
			v-if="showWarningModal"
			@close-modal="showWarningModal = false"
		/>
		<RequisitionsItemModalRating
			v-if="showRatingModal"
			@close-modal="rateRequest"
		/>
		<RequsitionsTableModal
			v-if="showEditModal"
			:requestId="this.$route.params.id"
			:images="item.images"
			:status="sys_name"
			:user-created="item.user ? (item.user.id === item.user_created.id ? true : false) : false"
			@update-info="loadData"
			@close-modal="showEditModal = false"
		/>
		<ModalWarning
			v-if="warningDialog"
			:title="'К сожалению уведомления отключены'"
			:text="'Для получения сведений о статусе заявки необходимо включить уведомления на сайте и перезагрузить страницу'"
			@close-modal="closeWarningModal"
		/>
		<notification-modal @close-modal="loadData"></notification-modal>
	</div>
</template>

<script>
import { mapGetters } from "vuex";

import { getCatalogValue } from "@/helpers/helpers.js";
import { statusColor } from "@/helpers/variables.js";

import DatePicker from "@/components/app/DatePicker";
import ImagesList from "@/components/app/ImagesList";
import ModalWarning from "@/components/app/ModalWarning";
import NotificationModal from "@/components/app/NotificationsModal";
import RequsitionsTableModal from "@/components/RequsitionsTableModal";
import RequisitionsExecutors from "@/components/RequisitionsExecutors";
import RequisitionsItemModalFinish from "@/components/RequisitionsItemModalFinish";
import RequisitionsItemModalRating from "@/components/RequisitionsItemModalRating";
import RequisitionsItemModalReject from "@/components/RequisitionsItemModalReject";
import RequisitionsItemModalWarning from "@/components/RequisitionsItemModalWarning";

export default {
	name: "RequsitionsItemInfo",
	components: {
		DatePicker,
		ImagesList,
		ModalWarning,
		RequsitionsTableModal,
		RequisitionsExecutors,
		NotificationModal,
		RequisitionsItemModalFinish,
		RequisitionsItemModalRating,
		RequisitionsItemModalReject,
		RequisitionsItemModalWarning,
	},
	props: ['itemInfo'],
	data() {
		return {
			dateCopy: new Date().toISOString().substr(0, 10),
			alert: null,
			item: null,
			warningDialog: false,
			loading: true,
			isChangingStatus: false,
			isContinueStatus: false,
			showEditModal: false,
			showFinishModal: false,
			showRejectModal: false,
			showWarningModal: false,
			showRatingModal: false,
			statusColor,
			result: null,
			manager_itr: false,
		};
	},
	computed: {
		...mapGetters({
			catalog: "catalog/GET_CATALOG",
			role: "auth/GET_USER_ROLE_VUEX",
			userId: "auth/GET_USER_ID",
			kskId: "auth/GET_KSK_ID",
			companyType: "auth/GET_COMPANY_TYPE",
			base_role: "auth/GET_USER_ROLE",
			base_technical_supervisor: "auth/GET_TECHNICAL_SUPERVISOR",
			base_techinal_engineers: "auth/GET_TECHINAL_ENGINEERS",
			technicalSupervisor: "auth/GET_TECHNICAL_SUPERVISOR_ROLE",
			tokenDevice: "notifications/GET_DEVICE_TOKEN",
		}),
		status() {
			return this.catalog?.request_types.find(
				e => e.id === this.item?.request_type.id
			);
		},
		sys_name() {
			return this.status.sys_name;
		},
		statusName() {
			return this.status.name;
		},
		editAvailable() {
			return (
				this.sys_name === "new" ||
				this.sys_name === "accepted" ||
				this.sys_name === "in_work"
			);
		},
		disabled() {
			// ? Отключить "Назначить исполнителя" и "Выбрать дату" для заявок со статусами
			// ? "Исполнено", "Отклонено", "Отменено пользователем"
			return (
				this.sys_name === "done" ||
				this.sys_name === "rejected" ||
				this.sys_name === "canceled_by_user" ||
				this.sys_name === "not_done" || 
				this.manager_itr
			);
		},
	},
	watch: {
		dateCopy(newValue) {
			this.$emit("update:date", newValue);
		},
		"$route.query"() {
			console.log('11312313123')
		},
		"$route"() {
			this.id = this.$route.params.id;
			this.loadData();
		},
		"itemInfo"() {
			this.item = this.itemInfo
			console.log('item', this.item)
			if (typeof this.item.period_execution === 'number') {
				const date = new Date(this.item.period_execution * 1000);

				const year = date.getFullYear();
				const month = String(date.getMonth() + 1).padStart(2, '0');
				const day = String(date.getDate()).padStart(2, '0');

				this.item.period_execution = `${year}-${month}-${day}`;
				console.log('this.item.period_execution', this.item.period_execution)
			}
			if(this.item.house.id && this.item.request_category.id && this.item.request_type.sys_name === 'new') {
				console.log('this.item.house.id', this.item.house.id)
				this.$store.dispatch("ksk/load_employee", {house_id: this.item.house.id, category_id: this.item.request_category.id, requestInfo: true});
			}
			if(this.base_role === 'dispatcher') {
				this.loadEmployees()
			}
			if(this.item) {
				this.loading = false
			}
		}
	},
	methods: {
		closeWarningModal() {
			this.warningDialog = false
		},
		async loadEmployees() {
			const params = {
				ksk_id: [this.item.company.id],
				house_id: [this.item.house.id],
				category_id: [this.item.request_category.id],
				selected_ksk_id: this.item.company.id
			}
			this.$store.dispatch("ksk/load_employee_dispatcher", params);
		},
		getApartment(item) {
			if(!item.house) return
			const address = item.house.address;
			let apt;
			// if (item.inner) {
			// 	const premise = getCatalogValue(item.premise_id, "premises");
			// 	return `${address}, ${premise}`.toLowerCase();
			// }
			// const place = this.catalog.premises.filter(e => e.id === item.premise_id)
			const apartment = item.room?.apartment_number || "";
			let porch_number = 'подъезд ' + item.room?.porch_number  + ',' || "" + ',';
			let floor_number = 'этаж ' + item.room?.floor_number  + ',' || "" + ',';
			if(!item.room?.porch_number) {
				porch_number = ''
			}
			if(!item.room?.floor_number) {
				floor_number = ''
			}
			
			if(item.room) {
				if(item.room.type) {
					if(item.room.type.name == 'Нежилое помещение') {
						apt = 'Помещение'
					} else if(item.room.type.name == 'Парковочное место') {
						apt = 'Парковочное место'
					} else {
						apt = 'кв.'
					}
				} else {
					apt = ''
				}
			} else {
				apt = 'кв.'
			}
			if(item.is_inner) {
				return `${address}, ${porch_number} ${floor_number} ${item.premise?.name}`;
			}
			return `${address}, ${porch_number} ${floor_number} ${apt} ${apartment || "---"}`;
		},
		async continueRequest() {
			try {
				this.isContinueStatus = true
				const data = {
					request_id: this.$route.params.id,
					firebase_token: this.tokenDevice,
					source: 2,
					// period_execution: this.dateCopy,
				};
				await this.$api.ksk.reopen_request(data)
				this.item = {
					is_pause: false
				}
				this.loadData('reopen');
				this.isContinueStatus = false
			} catch (error) {
				console.log('error', error)
				this.isContinueStatus = false
				this.alert = {
					color: "error",
					message: error.message || "Не удалось продолжить работу заявки",
				};
			}

		},
		// Принятие заявки
		async acceptRequest() {
			// if (!this.customValidation()) {
			// 	this.showWarningModal = true;
			// 	return;
			// }
			const employee_workers = [...this.item.employee_workers].map(e => e.id);
			if(!employee_workers.length || !this.item.period_execution) {
				return this.alert = {
					color: "warning",
					message: 'Назначьте исполнителя и заполните срок исполнения',
				};
			}
			try {
				const employee_workers = [...this.item.employee_workers].map(e => e.id);
				const data = {
					firebase_token: this.tokenDevice,
					employee_workers: employee_workers,
					period_execution: this.item.period_execution,
					manager_id: this.userId,
					is_price: this.item.is_price,
					source: 2,
					_method: "patch"
				};
				const res = await this.$api.ksk.assign_request(data, this.item.id)
				this.alert = {
					color: "success",
					message: res.message || 'Статус заявки успешно изменен',
				};
				const currentQuery = { ...this.$route.query };
				currentQuery.forget_cache = 'true';
				this.$router.replace({ query: currentQuery });
				this.$emit("update-list");
				setTimeout(() => this.loadData(), 500);
				this.isChangingStatus = false;
				this.alert = null;
				console.log('res', res)
			} catch (error) {
				console.log('error', error)
				this.alert = {
					color: "error",
					message: error.message || "Не удалось изменить статус заявки",
				};
			}
		},

		// ? Принятие заявки В РАБОТУ
		async acceptRequestForWork() {
			// if (!this.customValidation()) {
			// 	this.showWarningModal = true;
			// 	return;
			// }
			try {
				const employee_workers = [...this.item.employee_workers].map(e => e.id);
				const data = {
					firebase_token: this.tokenDevice,
					employee_worker_executor_id: employee_workers[0],
					_method: "patch",
					source: 2
				};
				const res = await this.$api.ksk.accept_request(data, this.item.id)
				this.alert = {
					color: "success",
					message: res.message || 'Статус заявки успешно изменен',
				};
				const currentQuery = { ...this.$route.query };
				currentQuery.forget_cache = 'true';
				this.$router.replace({ query: currentQuery });
				this.$emit("update-list");
				setTimeout(() => this.loadData(), 500);
				this.isChangingStatus = false;
				this.alert = null;
				console.log('res', res)
			} catch (error) {
				console.log('error', error)
				this.alert = {
					color: "error",
					message: error.message || "Не удалось изменить статус заявки",
				};
			}
		},

		// ? Изменение статуса заявки
		async changeRequestStatus(status, request_type_rejected_message) {
			try {
				this.isChangingStatus = true;
				console.log('status', status)
				this.result = status;
				if (status !== "done") await this.updateRequest(request_type_rejected_message);
				this.alert = {
					color: "success",
					message: 'Статус заявки успешно изменен',
				};
				this.$emit("update-list");
				this.isChangingStatus = false;
				// setTimeout(() => this.loadData(), 500);
				this.alert = null;
			} catch (error) {
				this.alert = {
					color: "error",
					message: error.message || "Не удалось изменить статус заявки",
				};
				console.log(error);
			}
		},

		// ? Закрытие заявки
		finishRequest(isConfirmed) {
			this.showFinishModal = false;
			if (isConfirmed === true) {
				const currentQuery = { ...this.$route.query };
				currentQuery.forget_cache = 'true';
				this.$router.replace({ query: currentQuery });
				this.$emit("update-list");
				setTimeout(() => this.loadData(), 500);
				// this.changeRequestStatus("done");
			}
		},

		// ? Оценивание заявки
		async rateRequest(isConfirmed, data) {
			this.showRatingModal = false;
			if (!isConfirmed) return;
			try {
				data.source = 2
				data.firebase_token = this.tokenDevice
				data._method = 'patch'
				const res = await this.$api[this.role].rate_request({
					id: this.item.id,
					data: data,
				});
				this.item.user_rating = data.user_rating;
				this.item.user_comment = data.user_comment;
				this.$root.snackbar.show({ color: "success", message: res.message }); 
			}	catch (error) {
				this.$root.snackbar.show({ color: "error", message: error.message });
			}
		},

		// Отклонение заявки
		async rejectRequest(isConfirmed, request_type_rejected_message) {
			this.showRejectModal = false;
			if (isConfirmed) {
				console.log('reject', request_type_rejected_message)
				// this.changeRequestStatus("rejected", request_type_rejected_message);
				try {
					const data = {
						firebase_token: this.tokenDevice,
						source: 2,
						employee_comment: request_type_rejected_message,
						_method: "patch"
					};
					const res = await this.$api.ksk.rejected_request_bpmn(data, this.item.id)
					this.alert = {
						color: "success",
						message: res.message || 'Заявка успешно отклонена',
					};
					this.$emit("update-list");
					setTimeout(() => this.loadData(), 500);
					this.isChangingStatus = false;
					this.alert = null;
					console.log('res', res)
				} catch (error) {
					console.log('error', error)
					this.alert = {
						color: "error",
						message: error.message || "Не удалось изменить статус заявки",
					};
				}
			}
		},

		// ? Обновление информации о заявке
		async updateRequest(request_type_rejected_message) {
			if(this.result == 'rejected') {
				const { is_price, employee_period_of_execution } = this.item;
				const employee_workers = [...this.item.employee_workers].map(e => e.id);
				const data = {
					// is_price,
					// employee_period_of_execution,
					// employee_worker_ids: employee_workers,
					// description: this.item.description,
					// is_price: this.item.is_price,
					request_type: this.result,
					// images: this.item.images,
					request_type_rejected_message: request_type_rejected_message,
					// employee_worker_executor_id: employee_workers[0],
				};

				const formData = data.toFormData();

				try {
					await this.$api.ksk.rejected_request(
						formData,
						this.$route.params.id
					);
				} catch (error) {
					console.error(error);
				}
			} else {
				const { is_price, employee_period_of_execution } = this.item;
				const employee_workers = [...this.item.employee_workers].map(e => e.id);
				const request_type = this.catalog.request_types.find(e => e.sys_name === this.result)
				console.log('this.item', this.item)
				const data = {
					is_price,
					employee_period_of_execution,
					employee_workers: employee_workers,
					description: this.item.description,
					is_price: this.item.is_price,
					request_type_id: request_type.id,
					_method: "patch",
					// images: this.item.images,
					employee_worker_executor_id: employee_workers[0],
				};

				// const formData = data.toFormData();

				try {
					const res = await this.$api.ksk.save_request(
						data,
						this.$route.params.id
					);
					this.item = res
				} catch (error) {
					console.error(error);
			}
			}
		},

		// Валидация
		customValidation() {
			if (
				!this.item.period_execution ||
				!this.item.employee_workers.length
			) {
				return false;
			}
			return true;
		},

		// ? Загрузка информации о заявке
		async loadData(status) {
			try {
				this.loading = true;
				// if(this.role == 'administrator') {
				this.item = await this.$api.ksk.load_request_detail(
					this.$route.params.id
				);
				if(status === 'reopen') {
					this.item.is_pause = false
				}
				setTimeout(() => {
					if(this.item.is_pause) {
						this.continueRequest()
					}
				}, 10);

				this.$emit("update-data", this.item);
				// * Изменить null на допустимые типы данных
				if (this.item.period_execution === null) {
					this.item.period_execution = "";
				}
				if (typeof this.item.period_execution === 'number') {
					const date = new Date(this.item.period_execution * 1000);

					const year = date.getFullYear();
					const month = String(date.getMonth() + 1).padStart(2, '0');
					const day = String(date.getDate()).padStart(2, '0');

					this.item.period_execution = `${year}-${month}-${day}`;
					console.log('this.item.period_execution', this.item.period_execution)
				}
				if (this.item.is_price === null) {
					this.item.is_price = false;
				}
				if (this.item.employee_workers === null) {
					this.item.employee_workers = [];
				}
				if (this.item.images === false) {
					this.item.images = [];
				}
				if(this.item.house.id && this.item.request_category.id && this.item.request_type.sys_name === 'new') {
					this.$store.dispatch("ksk/load_employee", {house_id: this.item.house.id, category_id: this.item.request_category.id, requestInfo: true});
				}
				if(this.base_role === 'dispatcher') {
					this.loadEmployees()
				}
			} catch (error) {
				throw error;
			} finally {
				this.loading = false;
			}
		},
		removeChip(index) {
			this.item.employee_workers.splice(index, 1);
		},
	},
	async mounted() {
		try {
			// this.$store.dispatch("catalog/load");
			// await this.loadData();
			setTimeout(() => {
				if(!this.tokenDevice) {
					return this.warningDialog = true		
				} 
			}, 1000);

		} catch (error) {
			console.log(error);
		}
	},
	created() {
		if(this.$route.query.is_pause === 'true') {
			this.continueRequest()
		}
		if(this.base_techinal_engineers && this.base_technical_supervisor) {
			this.manager_itr = true
		}
	},
};
</script>

<style scoped>
.text-vbtn {
	letter-spacing: 0.0892857143em;
	font-weight: 500;
	font-size: 0.75rem;
	padding: 0 12.4444444444px;
	text-transform: uppercase;
	color: #2eb85c;
}
</style>
