<template>
	<v-dialog v-model="dialog" persistent max-width="1000px">
		<v-card>
			<v-card-title class="d-flex justify-space-between border-bottom">
				<div class="d-flex">
					<span class="text-lowercase font-weight-light subtitle-2 mr-2">
						<span class="text-capitalize mr-1">Назначить</span>исполнителя
					</span>
				</div>
				<v-btn color="dark" @click="closeModal(false)" icon>
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-card-title>
			<v-card-text class="pa-0 border-bottom">
				<v-data-table
					v-model="selected"
					:headers="headers"
					:items="filteredEmployees"
					:loading="loading"
					item-key="id"
					class="elevation-0 no-pagination"
					show-select
					fixed-header
					height="400px"
					hide-default-footer
					:items-per-page="-1"
					@click:row="selectRow"
				>
					<template v-slot:[`body.prepend`]>
						<tr>
							<td></td>
							<td>
								<v-text-field
									v-model="filter.name"
									class="border-all"
									solo
									flat
									dense
									hide-details
								></v-text-field>
							</td>
							<td>
								<v-select
									v-model="filter.employee_position"
									class="border-all"
									solo
									flat
									dense
									clearable
									hide-details
									item-text="name"
									item-value="id"
									:items="catalog.employee_positions"
								>
								</v-select>
							</td>
							<td>
								<v-text-field
									v-model="filter.phone"
									class="border-all"
									:placeholder="phoneCode+'-'"
									v-mask="phoneCode == '+7' ? phoneCode+'-###-###-##-##' : phoneCode+'-###-###-####'"
									solo
									flat
									dense
									hide-details
								></v-text-field>
							</td>
							<!-- <td>
								<v-text-field
									v-model="filter.request_count"
									class="border-all"
									solo
									flat
									dense
									hide-details
								></v-text-field>
							</td> -->
						</tr>
					</template>
					<template v-slot:[`item.name`]="{ item }">
						<td>
							{{ item | fullname }}
						</td>
					</template>
					<template v-slot:[`item.employee_position`]="{ item }">
						<td>
							<span v-if="item.employee_positions.length">
								<p v-for="(empl, i) in item.employee_positions" :key="i" class="ma-0">
									{{empl.employee.name}}
								</p>
							</span>
							<span v-else>---</span>
						</td>
					</template>
				</v-data-table>
			</v-card-text>
			<v-card-actions class="d-flex justify-space-between">
				<v-btn
					class="subtitle-2 text-lowercase"
					color="primary"
					text
					@click="clearSelected"
				>
					<span class="text-capitalize mr-1">Снять</span>выбранные
				</v-btn>
				<v-btn
					class="text-lowercase font-weight-light subtitle-2"
					color="primary"
					depressed
					@click="closeModal(true)"
				>
					<span class="text-capitalize mr-1">Назначить</span>
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { getFullname, kazToRus } from "@/helpers/helpers";

import { mapGetters } from "vuex";

export default {
	name: "ExecutorsModal",
	props: {
		alreadySelected: {
			type: [Array, null],
			default: [],
		},
		categoryId: {
			type: String,
			default: "",
		},
		houseId: {
			type: String,
			default: "",
		},
		kskId: {
			type: String,
			default: "",
		},
	},
	data() {
		return {
			dialog: true,
			items: [],
			loading: false,
			filter: {
				name: "",
				phone: "",
				employee_position: "",
				request_count: "",
			},
			headers: [
				{
					text: "ФИО",
					align: "start",
					sortable: false,
					value: "name",
					filter: (value, search, item) => {
						if (!this.filter.name) return true;
						const fullname = getFullname(item);
						const source = kazToRus(fullname).toLowerCase();
						const find = kazToRus(this.filter.name).toLowerCase();
						return source.includes(find);
					},
				},
				{
					text: "Должность",
					value: "employee_position",
				},
				{
					text: "Телефон",
					value: "phone",
					filter: value => {
						if (!this.filter.phone) return true;
						if (!value) return false;
						return value.includes(this.filter.phone);
					},
				},
				// { text: "Текущие заявки", value: "request_count" },
			],
			selected: [],
		};
	},
	computed: {
		...mapGetters({
			catalog: "catalog/GET_CATALOG",
			employees: "ksk/GET_EMPLOYEE",
			role: "auth/GET_USER_ROLE_VUEX",
			phoneCode: "auth/GET_USER_PHONE_CODE",
		}),
		filteredEmployees() {
			if (!this.filter.employee_position) {
				return this.employees; // Если должность не выбрана, вернуть все сотрудников
			} else {
				return this.employees.filter(employee => employee.employee_positions.some(position => position.employee.id === this.filter.employee_position));
			}
		}
	},
	methods: {
		clearSelected() {
			this.selected = [];
		},
		closeModal(isConfirmed = false) {
			this.dialog = false;
			if (!isConfirmed) {
				this.$emit("close-modal", this.alreadySelected);
			} else {
				this.$emit("close-modal", this.selected);
			}
		},
		selectRow(e) {
			const index = this.selected.findIndex(s => s.id === e.id);
			if (index !== -1) {
				this.selected.splice(index, 1);
			} else {
				this.selected.push(e);
			}
		},
		async loadEmployee() {
			try {
				this.loading = true;
				if(this.role !== 'dispatcher') {
					this.items = await this.$api.ksk.load_employee_list({
						house_id: this.houseId,
						category_id: this.categoryId,
					});
				} else {
					this.items = await this.$api.ksk.load_employee_list({
						house_id: this.houseId,
						category_id: this.categoryId,
						ksk_id: this.kskId
					});
				}

				// this.items = this.items.filter(e =>
				// 	e.category_ids.includes(this.categoryId)
				// );

				// Если нет сотрудников по дому, то загрузить всех
				if (!this.items.length) {
					this.items = await this.$api.ksk.load_employee_list();
				}
			} catch (error) {
				throw error;
			} finally {
				this.loading = false;
			}
		},
	},
	created() {
		this.selected = [...this.alreadySelected];
	},
	// async mounted() {
	// 	try {
	// 		await this.loadEmployee();
	// 	} catch (error) {
	// 		console.error(error);
	// 	}
	// },
};
</script>

<style lang="scss" scoped>
::v-deep .v-card__text {
	max-height: 500px !important;
}

::v-deep .v-text-field.v-text-field--solo.v-input--dense > .v-input__control {
	min-height: 28px !important;
	font-size: 14px !important;
}
</style>
